<script setup lang="ts">
withDefaults(
  defineProps<{ error?: object }>(),
  { error: () => Object() },
);

useHead({
  title: 'Error',
  meta: [
    {
      hid: 'description',
      name: 'description',
      content: 'An error occurred on Pilot Car Loads.',
    },
  ],
});

const { loggedIn } = storeToRefs(useAuthStore());
const { userData } = storeToRefs(useUserStore());

const userType = computed(() => userData.value?.type);

const nextRoute = () => {
  const routeInfo = { to: '/', text: 'Return Home' };

  if (loggedIn.value) {
    routeInfo.text = 'Return To Dashboard';
    if (userType.value === 'shipper') {
      routeInfo.to = '/dashboard/post-load';
    } else if (userType.value === 'driver') {
      routeInfo.to = '/dashboard/loads';
    } else {
      routeInfo.to = '/dashboard/settings';
    }
  }

  return routeInfo;
};
</script>

<template>
  <div class="bg-gray-100">
    <container class="py-10 text-center">
      <section class="mx-auto max-w-md">
        <div v-if="error.statusCode === 404">
          <h1 class="text-3xl font-bold">
            404 Page not found
          </h1>
          <p>Sorry, the page you're looking for doesn't exist.</p>
        </div>
        <div v-else>
          <h1 class="text-3xl font-bold">
            Unknown Error
          </h1>
          <p>Sorry, an unknown error occurred, try clicking the button below to return. If this error continues, please use the chat in the lower left to contact us for help.</p>
        </div>

        <a
          :href="nextRoute.to"
          class="mx-auto my-8 flex items-center justify-center rounded-md border border-transparent bg-white px-5 py-3 text-sm font-medium leading-6 text-teal-700 shadow transition duration-150 ease-in-out hover:text-teal-500 focus:border-teal-300 focus:outline-none"
        >
          {{ nextRoute.text }}
        </a>
      </section>
    </container>
  </div>
</template>
